<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">English Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
<!--          category-->
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="en.category_id"
                rules="required"
                name="The Category"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="category_id"
                  size="large"
                  filterable
                  clearable
                  placeholder="Category"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="'Category'"
                  :list="builderData.categoryList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.category_id">
                </fg-select>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="en.type_id"
                rules=""
                name="The Type"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="type_id"
                  size="large"
                  filterable
                  clearable
                  placeholder="Article Type"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="'Article Type'"
                  :list="builderData.typeList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.type_id">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>
<!--          title & url-->
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="en.title"
                rules="required"
                name="The Title"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Title"
                          name="title"
                          fou
                          @keyup="generateSomeFields"
                          v-model="formData.title">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="en.url"
                rules="required"
                name="The URL"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="URL"
                          name="url"
                          fou
                          v-model="formData.url">
                </fg-input>
              </ValidationProvider>
            </div>

          </div>
<!--          subtitle-->
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.subtitle"
                rules=""
                name="The Subtitle"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Subtitle"
                          name="subtitle"
                          fou
                          v-model="formData.subtitle">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
<!--          date & expert-->
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="en.expert_id"
                rules=""
                name="The Expert"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="expert_id"
                  size="large"
                  filterable
                  clearable
                  placeholder="Expert"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="'Expert'"
                  :list="builderData.expertList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.expert_id">
                </fg-select>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <label class="card-label">Date</label>
              <fg-input v-model="formData.date"
                        value-format="YYYY-MM-DD HH:MM:SS"
                        type="datetime-local"
                        placeholder="Chose article date"
              >
              </fg-input>
            </div>
          </div>
<!--          Tags-->
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.tags"
                rules=""
                name="The Tags"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="tags"
                  size="large"
                  filterable
                  clearable
                  multiple
                  placeholder="Tags"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="'Tags'"
                  :list="builderData.tagList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.tags">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>
<!--          related article-->
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.related_articles"
                rules=""
                name="The Related Articles"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="related_articles"
                  size="large"
                  filterable
                  clearable
                  multiple=""
                  placeholder="Related Articles"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="'Related Articles'"
                  :list="builderData.articleList"
                  :listItemLabel="'title'"
                  :listItemValue="'id'"
                  v-model="formData.related_articles">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>
<!--          content-->
          <div class="form-group">
            <label>Content</label>
            <editor
              v-model="formData.content"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>
<!--          banner image-->
          <div class="form-group">
            <label>Banner Image</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="1"
              v-model="formData.banner_image"
            >
            </prime-uploader>
          </div>
<!--          image-->
          <div class="form-group">
            <label>Image</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="1"
              v-model="formData.image"
            >
            </prime-uploader>
          </div>
<!--          gallery-->
          <div class="form-group">
            <label>Gallery</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="999"
              :withDescription=true
              v-model="formData.gallery"
            >
            </prime-uploader>
          </div>
<!--          list items-->
          <label>List Items</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.list_items">
              <div class="row article_list_items">
                <label>Item # {{ listItemIndex + 1 }}</label>
<!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="en.itemtitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="'Title '+(listItemIndex+1)"
                              v-model="formData.list_items[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
<!--                list item content-->
                <div class="col-md-12">
                  <label>{{ 'Content ' + (listItemIndex + 1) }}</label>
                  <editor
                    :key="'content_'+listItemIndex"
                    v-model="formData.list_items[listItemIndex].content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
<!--                list item image-->
                <div class="col-md-12">
                  <label>{{ 'Image ' + (listItemIndex + 1) }}</label>
                  <el-tooltip placement="right" v-if="false">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.list_items[listItemIndex].image"
                  >
                  </prime-uploader>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.list_items, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.list_items)"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>

<!--          is active-->
          <div class="form-group pt-4">
            <label>Is Active</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Option, Select, Tooltip} from "element-ui";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor
    },
    props: {
      lang: {
        type: String,
        default: 'en'
      },
      editMode: {
        type: Boolean,
        default: false
      },
      builderData:{},
      formData:{
        type: Object,
        default() {
          return {
            title: "",
            subtitle: "",
            url: "",
            content: "",
            gallery: [],
            image: "",
            banner_image: "",
            date: "",
            is_active: true,
            related_articles: [],
            list_items: [],
            tags: [],
            category_id: "",
            type_id: "",
            expert_id: "",
          }
        }
      },
    },
    data() {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      };
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      generateSomeFields() {
        if (this.editMode){
          return
        }
        let newUrl = '';
        if (this.formData.title) {
          newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "-");
        }
        this.formData.url = newUrl;
      },
      addNewItem(listItems) {
        listItems.push({title: '', content: '', image: ''});
      },
      removeItem(listItems, index) {
        listItems.splice(index, 1);
      },
    }
  }
</script>
<style>
.article_list_items{
  border: 1px solid #cccc;
  padding: 10px 45px 30px 19px;
}
</style>
